import AppLayout from "@/layouts/AppLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import { createRouter, createWebHashHistory } from "vue-router";
import { authGuard } from "./auth-guard";
import { organizationGuard } from "./organization-guard";

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
        {
            name: "index",
            path: "/",
            components: {
                default: () => import("@/views/IndexView.vue"),
            },
            meta: { requiresAuth: true, layout: AppLayout },
        },
        {
            name: "login",
            path: "/login",
            component: () => import("@/views/LoginView.vue"),
            props: route => ({
                redirect: route.query.redirect,
                email: route.query.email,
            }),
            meta: { requiresAuth: false, layout: EmptyLayout },
        },
        {
            name: "register",
            path: "/register",
            component: () => import("@/views/RegisterView.vue"),
            meta: { requiresAuth: false, layout: EmptyLayout },
        },
        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import("@/views/auth/ForgotPasswordView.vue"),
            meta: { requiresAuth: false, layout: EmptyLayout },
        },
        {
            path: "/reset-password",
            name: "reset-password",
            component: () => import("@/views/auth/ResetPasswordView.vue"),
            meta: { requiresAuth: false, layout: EmptyLayout },
        },
        {
            path: "/admin/select-organization",
            name: "selectOrganization",
            component: () => import("@/components/organizations/OrganizationPicker.vue"),
            meta: { requiresAuth: false, layout: EmptyLayout },
        },
        {
            path: "/profile",
            name: "profile",
            component: () => import("@/views/profile/ProfileView.vue"),
            meta: { requiresAuth: true, layout: AppLayout },
        },
        {
            name: "delegations",
            path: "/delegations",
            components: {
                default: () => import("@/views/DelegationsView.vue"),
            },
            meta: { requiresAuth: true, layout: AppLayout },
        },
        {
            name: "recovery-tokens",
            path: "/recovery-tokens",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/RecoveryTokensView.vue"),
            },
        },
        {
            name: "registry",
            path: "/registry",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/registry/RegistryView.vue"),
            },
        },
        {
            name: "recovery-tokens-consume",
            path: "/recovery-tokens/:id/consume",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/recovery-tokens/ConsumeRecoveryTokenView.vue"),
            },
        },
        {
            name: "communication",
            path: "/communications",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/CommunicationsView.vue"),
            },
        },
        {
            name: "documents",
            path: "/documents",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/documents/DocumentsView.vue"),
            },
        },
        {
            name: "orders",
            path: "/orders",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/orders/OrdersView.vue"),
            },
        },
        {
            name: "order-read",
            path: "/orders/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/orders/OrderReadView.vue"),
            },
        },
        {
            name: "accountability",
            path: "/accountability",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/accountability/AccountabilityOrdersView.vue"),
            },
        },
        {
            name: "members",
            path: "/members/all",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/MembersView.vue"),
            },
        },
        {
            name: "member-create",
            path: "/members/create",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/MemberCreate.vue"),
            },
        },
        {
            name: "participants",
            path: "/members/participants",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/participants/ParticipantsView.vue"),
            },
        },
        {
            name: "participants-read",
            path: "/members/participants/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/participants/ParticipantsReadView.vue"),
            },
        },
        {
            name: "holders",
            path: "/members/holders",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/members/holders/HoldersView.vue"),
            },
        },
        {
            name: "services",
            path: "/services",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/activities/services/ServicesView.vue"),
            },
        },
        {
            name: "service-blueprints",
            path: "/service-blueprints",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/activities/services-blueprints/ServiceBlueprintsView.vue"),
            },
        },
        {
            name: "service-read",
            path: "/services/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/activities/services/ServiceReadView.vue"),
            },
        },
        {
            name: "service-blueprint-read",
            path: "/service-blueprints/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/activities/services-blueprints/ServiceBlueprintReadView.vue"),
            },
        },
        {
            name: "service-create",
            path: "/services/create",
            meta: { requiresAuth: true, layout: EmptyLayout },
            component: () => import("@/views/activities/services/ServiceCreateView.vue"),
        },
        {
            name: "prestation-read",
            path: "/prestations/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/activities/prestations/PrestationReadView.vue"),
            },
        },
        {
            name: "periods",
            path: "/periods",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/periods/PeriodsView.vue"),
            },
        },
        {
            name: "period-read",
            path: "/periods/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/periods/PeriodReadView.vue"),
            },
        },
        {
            name: "periods-create",
            path: "/periods/create",
            meta: { requiresAuth: true, layout: EmptyLayout },
            component: () => import("@/views/periods/PeriodCreateView.vue"),
        },
        {
            name: "period-manage-services",
            path: "/periods/:id/manage-services",
            meta: { requiresAuth: true, layout: EmptyLayout },
            component: () => import("@/views/periods/PeriodManageServices.vue"),
        },
        {
            name: "period-plan-next",
            path: "/periods/:id/plan-next",
            meta: { requiresAuth: true, layout: EmptyLayout },
            component: () => import("@/views/periods/PeriodPlanNext.vue"),
        },
        {
            name: "configuration-organizations",
            path: "/configuration/organizations/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/configuration/organizations/OrganizationsConfigurationView.vue"),
            },
        },
        {
            name: "organizations",
            path: "/organizations",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/organizations/OrganizationsView.vue"),
            },
        },
        {
            name: "organization-create",
            path: "/organizations/create",
            meta: { requiresAuth: true, layout: AppLayout },
            component: () => import("@/views/organizations/OrganizationCreateView.vue"),
        },
        {
            name: "organization-read",
            path: "/organizations/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/organizations/OrganizationReadView.vue"),
            },
        },
        {
            name: "billing",
            path: "/billing",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/invoices/OrganizationsInvoicesView.vue"),
            },
        },
        {
            name: "billing-read",
            path: "/billing/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/invoices/organizations/OrganizationInvoiceReadView.vue"),
            },
        },
        {
            name: "configuration-establishments",
            path: "/configuration/establishments/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/configuration/establishments/EstablishmentsConfigurationView.vue"),
            },
        },
        {
            name: "establishments",
            path: "/establishments",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/establishments/EstablishmentsView.vue"),
            },
        },
        {
            name: "establishment-read",
            path: "/establishments/:id",
            meta: { requiresAuth: true, layout: AppLayout },
            components: {
                default: () => import("@/views/establishments/EstablishmentReadView.vue"),
            },
        },
        {
            name: "establishment-create",
            path: "/establishments/create",
            meta: { requiresAuth: true, layout: AppLayout },
            component: () => import("@/views/establishments/EstablishmentCreateView.vue"),
        },
    ],
});

// Navigation guards
router.beforeEach(organizationGuard);
router.beforeEach(authGuard);

export default router;
